<template>
  <div>
    <!-- 导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>工作管理</el-breadcrumb-item>
      <el-breadcrumb-item>工资管理</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片试图 -->
    <el-tabs v-model="activeName" @tab-click="handleClickTab">
      <!-- 查询区域 -->
      <div>
        <el-form layout="inline" :model="queryParams" :inline="true">
          <el-row :gutter="24">
            <el-col :md="6" :sm="8">
              <el-form-item label="计划购买月份">
                <el-date-picker
                  v-model="queryParams.planData"
                  type="month"
                  placeholder="选择日期"
                  format="yyyy 年 MM 月"
                  value-format="yyyy-MM"
                  @change="queryDataList"
                ></el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-row>
          <el-col :md="6" :sm="24" style="width: 70%">
            <el-button type="primary" @click="queryDataList">搜索</el-button>
            <el-button type="primary">重置</el-button>
          </el-col>
        </el-row>
      </div>

      <el-tab-pane label="登记列表" name="first" :key="'first'">
        <!-- 按钮区 -->
        <el-row :gutter="20" style="margin: 9px 2px">
          <el-button
            type="success"
            icon="el-icon-add"
            size="min"
            @click="addFromButton"
            >新增</el-button
          >
          <el-button
            type="success"
            icon="el-icon-add"
            size="min"
            @click="editFromButton"
            >编辑</el-button
          >
          <el-button
            type="success"
            icon="el-icon-edit"
            size="min"
            @click="buyShopButton"
            >购买</el-button
          >
          <el-button
            type="danger"
            icon="el-icon-add"
            size="min"
            @click="deletelifeshopButton"
            >删除</el-button
          >
          <el-button
            type="success"
            icon="el-icon-edit"
            size="min"
            :style="{ display: 'none' }"
            >收藏</el-button
          >
          <el-button type="success" icon="el-icon-add" size="min" disabled
            >查看</el-button
          >
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="购买列表" ref="child2" name="second" :key="'second'">
        <!-- 按钮区 -->
        <el-row :gutter="20" style="margin: 9px 2px">
          <el-button
            type="success"
            icon="el-icon-add"
            size="min"
            @click="editFromButton"
            >编辑</el-button
          >
          <el-button
            type="danger"
            icon="el-icon-add"
            size="min"
            @click="deletelifeshopButton"
            >删除</el-button
          >
          <el-button
            type="success"
            icon="el-icon-edit"
            size="min"
            :style="{ display: 'none' }"
            >收藏</el-button
          >
          <el-button type="success" icon="el-icon-edit" size="min" disabled
            >设置</el-button
          >
          <el-button type="success" icon="el-icon-add" size="min" disabled
            >查看</el-button
          >
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="收藏列表" ref="child3" name="three" :key="'three'">
        <!-- 按钮区 -->
        <el-row :gutter="20" style="margin: 9px 2px">
          <el-button
            type="success"
            icon="el-icon-add"
            size="min"
            @click="editFromButton"
            >编辑</el-button
          >
          <!-- <el-button type="danger" icon="el-icon-add" size="min" @click="deletelifeshopButton">删除</el-button> -->
          <el-button type="success" icon="el-icon-edit" size="min" disabled
            >设置</el-button
          >

          <el-button type="success" icon="el-icon-add" size="min" disabled
            >查看</el-button
          >
          <el-button
            type="success"
            icon="el-icon-edit"
            size="min"
            :style="{ display: 'none' }"
            >分享</el-button
          >
        </el-row>
      </el-tab-pane>

      <el-tab-pane label="最新功能" ref="child4" name="four" :key="'four'">
        <!-- 按钮区 -->
        <el-row :gutter="20" style="margin: 9px 2px">
          <el-button
            type="success"
            icon="el-icon-add"
            size="min"
            @click="newAddButtonModel('1')"
            >新增购物</el-button
          >
          <el-button
            type="success"
            icon="el-icon-add"
            size="min"
            @click="newAddButtonModel('2')"
            >视频新增</el-button
          >
          <el-button
            type="success"
            icon="el-icon-add"
            size="min"
            @click="newAddButtonModel('3')"
            >书籍新增</el-button
          >
          <el-button
            type="success"
            icon="el-icon-add"
            size="min"
            @click="newAddButtonModel('4')"
            >规则新增</el-button
          >
          <div>
            <el-button @click="checkURLAccessibility">Check URL</el-button>
            <p>URL Accessibility: {{ isAccessible }}</p>
          </div>
        </el-row>
      </el-tab-pane>

      <el-tab-pane label="最新功能-食物" ref="child5" name="five" :key="'five'">
        <addFood  ref="addFoodModules" />
      </el-tab-pane>


      <!-- 列表数据 -->
      <el-table :data="dataColumnList" show-summary v-if="this.activeName!='five'">
        <el-table-column
          label="选择"
          width="70"
          header-align="center"
          align="center"
        >
          <template slot-scope="scope">
            <el-radio
              class="radio"
              v-model="questionnaireId"
              :label="scope.row.id"
              @click.native.stop.prevent="getCurrentRow(scope.row)"
              >&nbsp;</el-radio
            >
          </template>
        </el-table-column>
        <el-table-column label="名称" align="center" prop="name" />
        <el-table-column label="级别" align="center" prop="grade" />
        <el-table-column
          label="购买链接"
          align="center"
          prop="buyUrl"
          show-overflow-tooltip
        />
        <el-table-column label="购买地址" align="center" prop="address" />
        <!-- <el-table-column label="一级分类" align="center" prop="status" /> -->
        <el-table-column label="预估价格" align="center" prop="predictPrice" />
        <el-table-column label="付款价格" align="center" prop="price" />
        <el-table-column label="购物数量" align="center" prop="number" />
        <!-- <el-table-column label="实际价格" align="center" prop="actualPrice" /> -->
        <el-table-column label="加入计划" align="center" prop="isPlan" />
        <el-table-column label="计划购买时间" align="center" prop="planData" />
        <el-table-column label="购买日期" align="center" prop="buyData" />
        <el-table-column label="是否收藏" align="center" prop="isCollect" />
        <!-- <el-table-column label="下次购买周期" align="center" prop="buyCycle" /> -->
        <!-- <el-table-column label="购物状态" align="center" prop="invalidTime" /> -->
      </el-table>
      <pagination
      v-if="this.activeName!='five'"
        :total="queryParams.total"
        :page-size.sync="queryParams.pageSize"
        :current-page.sync="queryParams.currentPage"
      />
    </el-tabs>
    <affEditShop ref="affEditShopModules" />
    <addNewShop ref="addNewShopModules" />
    <!-- <addNewBook ref="addNewBookModules" /> -->
    <addNewView ref="addNewViewModules" />
    <addNewFree ref="addNewFreeModules" />
  </div>
</template>

<script>
import addNewShop from "./newModules/addShop";
// import addNewBook from "./newModules/addBook";
import addNewFree from "./newModules/addFree";
import addNewView from "./newModules/addView";
import addFood from "./newModules/indexFood";

import affEditShop from "./modules/addEditShop";

import {
  listlifeshopApi,
  deletelifeshopByIdApi,
} from "@/api/life/shop/lifeshop";
import { getWorkEnumList } from "@/api/commonApi/commonInternal/commonInternal";
// addNewBook,
export default {
  name: "salary",
  components: {
    affEditShop,
    addNewShop,
    
    addNewView,
    addNewFree,
    addFood
  },
  data() {
    return {
      isAccessible: false,
      dataColumnList: [],
      questionnaireId: "",
      gradeList: [],
      queryParams: {
        query: "",
        recordData: "",
        userCompany: "",
        pagenum: 1,
        pagesize: 2,
        shopType: 1,
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
      queryAll: {},
      salaryList: [],
      total: 1,
      toggleSearchStatus: false,
      activeName: "five",
      id: null,
      radio: "",
    };
  },
  watch: {
    "queryParams.currentPage"(val) {
      this.queryDataList();
    },
    "queryParams.pageSize"(val) {
      this.queryDataList();
    },
  },
  created() {
    this.initDataList();
    this.queryDataList();
  },
  methods: {
    handleClickTab(tab, event) {
      if (tab.name == "first") {
        this.queryParams.shopType = 1;
      }
      if (tab.name == "second") {
        this.queryParams.shopType = 2;
      }
      if (tab.name == "three") {
        this.queryParams.shopType = 3;
      }
      this.id = null;
      this.questionnaireId = null;
      this.queryDataList();
    },
    addFromButton() {
      this.$refs.affEditShopModules.showFund(null, 1);
    },
    editFromButton() {
      if (this.id == undefined || this.id == null)
        return this.$message.error("请选择一条数据");
      this.$refs.affEditShopModules.showFund(this.id, 2);
    },
    buyShopButton() {
      if (this.id == undefined || this.id == null)
        return this.$message.error("请选择一条数据");
      this.$refs.affEditShopModules.showFund(this.id, 5);
    },
    queryDataList() {
      listlifeshopApi(this.queryParams).then((res) => {
        this.queryParams.total = res.result.total;
        this.dataColumnList = res.result.records;
        this.questionnaireId = null;
        this.id = null;
        this.templateSelection = {};
      });
    },
    initDataList() {
      getWorkEnumList({ enumName: "PlanGradeEnum" }).then((res) => {
        this.gradeList = res.result;
      });
    },
    //再次点击取消单选选中状态
    getCurrentRow(row) {
      //this.questionnaireId 为上一次存储的id与这一次比对判断是否和上次点击的一致
      if (this.questionnaireId == row.id) {
        this.questionnaireId = null;
        this.id = null;
        this.templateSelection = {};
      } else {
        this.questionnaireId = row.id;
        this.id = row.id;
        this.templateSelection = row;
      }
    },
    deletelifeshopButton() {
      if (this.id == undefined || this.id == null)
        return this.$message.error("请选择一条数据");
      deletelifeshopByIdApi(this.id).then((res) => {
        this.queryDataList();
      });
    },
    newAddButtonModel(row) {
      if (row == 1) {
        this.$refs.addNewShopModules.show();
      }
      if (row == 2) {
        this.$refs.addNewViewModules.show();
      }
      if (row == 3) {
        this.$refs.addNewBookModules.show();
      }
      if (row == 4) {
        this.$refs.addNewFreeModules.show();
      }
    },
    checkURLAccessibility: function () {
      console.log("start-");
      fetch(this.url, { mode: "no-cors" })
        .then((response) => {
          this.isAccessible = true;
        })
        .catch((error) => {
          console.log("error-");
          this.isAccessible = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
</style> 