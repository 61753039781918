import { getAction,getByIdAction,postAction,putAction,deleteByIdAction } from '@/api/common/request'
const baseURL = 'xun/work/xunLifeShop'


const listlifeshopApi =(params)=>getAction(baseURL+"/list",params);

const addlifeshopApi=(params)=>postAction(baseURL+"/add",params);

const editlifeshopApi=(params)=>putAction(baseURL+"/edit",params);

const deletelifeshopByIdApi=(params)=>deleteByIdAction(baseURL+'/deleteById',params);

const buyShopApi=(params)=>postAction(baseURL+"/buyShop",params);

const selectlifeshopByIdApi=(params)=>getByIdAction(baseURL+'/selectById',params);

export{
    selectlifeshopByIdApi,
    deletelifeshopByIdApi,
    editlifeshopApi,
    addlifeshopApi,
    listlifeshopApi,
    buyShopApi
}
