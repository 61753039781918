<template>
  <div>
    <!-- 还需要一个系统配置的功能 配置全局的 logo 名称  图片-->
    <!--  系统集合配置： 默认首页 页面标题 标题点击跳转 页面logo 页面logo跳转 隐藏菜单 -->
    <el-dialog
      title="购物"
      :visible.sync="drawer"
      :before-close="handleClose"
   
      width="40%"
    >
    <!-- :max-height="maxHeight" -->
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="名称" prop="name">
              <el-input v-model="ruleForm.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="适合季节" prop="name">
              <el-input v-model="ruleForm.name"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="5">
            <el-form-item label="数量" prop="names">
              <el-input
                type="number"
                v-model="ruleForm.names"
                style="width: 80px"
              />
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="单位" prop="name">
              <el-input v-model="ruleForm.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="预估价格" prop="name">
              <el-input v-model="ruleForm.name"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="5">
            <el-form-item label="属性" prop="name">
              <el-input v-model="ruleForm.name" style="width: 80px" />
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="级别" prop="name">
              <el-input v-model="ruleForm.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="物品归属" prop="name">
              <el-input v-model="ruleForm.name"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="维护周期" prop="name">
              <el-input v-model="ruleForm.name"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="再购买时间" prop="name">
              <el-input v-model="ruleForm.name"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item label="描述" prop="name">
              <el-input v-model="ruleForm.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="标签" prop="name">
              <el-input v-model="ruleForm.name"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="22">
            <el-form-item label="种类" prop="name">
              <el-input v-model="ruleForm.name"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="22">
            <el-form-item label="备注" prop="name">
              <el-input v-model="ruleForm.name"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="22">
            <el-form-item label="原链接" prop="name">
              <el-input v-model="ruleForm.name"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="是否收藏" prop="name">
              <el-checkbox v-model="checked"></el-checkbox>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="是否公开" prop="name">
              <el-checkbox v-model="checked"></el-checkbox>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="是否提醒" prop="name">
              <el-checkbox v-model="checked"></el-checkbox>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="是否标记" prop="name">
              <el-checkbox v-model="checked"></el-checkbox>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-form-item label="图片" prop="name">
            <el-upload
              class="upload-demo"
              :action="uploadUrl"
              :on-success="handleSuccess"
              :list-type="listType"
              :on-exceed="handleExceed"
              :auto-upload="false"
              :file-list="fileList"
              :limit="3"
            >
              <el-button slot="trigger" size="small" type="primary"
                >选取文件</el-button
              >
              <div slot="tip" class="el-upload__tip">
                只能上传3个文件，每个文件不超过500kb，且只能上传jpg/png文件
              </div>
            </el-upload>
          </el-form-item>
        </el-row>
        <el-row>
          <el-tabs
            v-model="activeName"
            type="border-card"
            @tab-click="handleClick"
          >
            <el-tab-pane label="高级属性" name="first">
              <el-row>
                <el-col :span="8">
                  <el-form-item label="店铺" prop="name" label-position="left">
                    <el-input v-model="ruleForm.name" style="width: 80%" />
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item
                    label="制造商"
                    prop="name"
                    label-position="left"
                  >
                    <el-input v-model="ruleForm.name" style="width: 80%" />
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="产地" prop="name" label-position="left">
                    <el-input v-model="ruleForm.name" style="width: 80%" />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="SKU" prop="name" label-position="left">
                    <el-input v-model="ruleForm.name" style="width: 80%" />
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="品牌" prop="name" label-position="left">
                    <el-input v-model="ruleForm.name" style="width: 80%" />
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="重量" prop="name" label-position="left">
                    <el-input v-model="ruleForm.name" style="width: 80%" />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="尺寸" prop="name" label-position="left">
                    <el-input v-model="ruleForm.name" style="width: 80%" />
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="颜色" prop="name" label-position="left">
                    <el-input v-model="ruleForm.name" style="width: 80%" />
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="材质" prop="name" label-position="left">
                    <el-input v-model="ruleForm.name" style="width: 80%" />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-form-item label="规格" prop="name" label-position="left">
                  <el-input v-model="ruleForm.name" />
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item
                  label="自定义字段"
                  prop="name"
                  label-position="left"
                >
                  <el-input v-model="ruleForm.name" />
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item label="评价" prop="name" label-position="left">
                  <el-input v-model="ruleForm.name" />
                </el-form-item>
              </el-row>
            </el-tab-pane>
            <el-tab-pane label="转化记录" name="second">
              <el-row>
                <el-col :span="8">
                  <el-form-item
                    label="转化来源"
                    prop="name"
                    label-position="left"
                  >
                    <el-input v-model="ruleForm.name" style="width: 80%" />
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="" prop="name" label-position="left">
                    <el-button>转化来源</el-button>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-table :data="tableData" style="width: 100%">
                <el-table-column prop="date" label="序号" />
                <el-table-column prop="date" label="获取时间" />
                <el-table-column prop="name" label="转化来源" />
                <el-table-column prop="name" label="转化链接" />
                <el-table-column prop="address" label="状态" />
                <el-table-column prop="address" label="操作" />
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="购买记录" name="threes">
              <el-table :data="tableData" style="width: 100%">
                <el-table-column prop="date" label="原来价格" />
                <el-table-column prop="date" label="付费价格" />
                <el-table-column prop="name" label="佣金价格" />
                <el-table-column prop="name" label="折扣价格" />
                <el-table-column prop="name" label="购买地" />
                <el-table-column prop="address" label="状态" />
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="分享内容" name="fourth">
              待调整--建议单独抽出一个功能的页面
              <el-row>
                <el-form-item
                  label="分享链接"
                  prop="name"
                  label-position="left"
                >
                  <el-input v-model="ruleForm.name" style="width: 80%" />
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item label="标题" prop="name" label-position="left">
                  <el-input v-model="ruleForm.name" style="width: 80%" />
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item label="内容" prop="name" label-position="left">
                  <el-input v-model="ruleForm.name" style="width: 80%" />
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item
                  label="分享文案"
                  prop="name"
                  label-position="left"
                >
                  <el-input v-model="ruleForm.name" style="width: 80%" />
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item
                  label="是否发实物图"
                  prop="name"
                  label-position="left"
                >
                  <el-input v-model="ruleForm.name" style="width: 80%" />
                </el-form-item>
              </el-row>
              <el-row>
                <el-button style="margin: 0px 40%" @click="previewButton"
                  >预览</el-button
                >
              </el-row>
            </el-tab-pane>
          </el-tabs>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      checked: true,
      previewShow: false,
      analyzeShow: false,
      fits: ["fill", "contain", "cover"],
      url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
      drawer: false,
      activeName: "first",
      ruleForm: {},
      rules: {},
    //   maxHeight: 400, // 设置max-height为窗口高度的一部分
      tableData: [],
      uploadUrl: "your-upload-url",
      fileList: [],
      listType: "picture-card", // 'text' or 'picture-card'
    };
  },
  mounted() {
    this.maxHeight = window.innerHeight * 0.8; // 设置max-height为窗口高度的80%
  },
  methods: {
    handleClick(tab, event) {
      if (this.previewShow) {
        this.previewShow = !this.previewShow;
      }
      if (this.analyzeShow) {
        this.analyzeShow = !this.analyzeShow;
      }

      console.log(tab, event);
    },
    show(row, type) {
      this.drawer = true;
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleSuccess(response, file, fileList) {
      console.log(response, file, fileList);
    },

    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    previewButton() {
      this.previewShow = !this.previewShow;
    },
  },
};
</script>
<style  lang="less" scoped>
.demo-ruleForm {
  overflow-y: auto;
  max-height: 90%; // 设置最大高度
}

.demo-drawer__footer {
  display: flex;
  justify-content: flex-end;
  padding: 0px 10px;
  border-top: 1px solid #ebeef5;
}

.ingredient-div {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.ingredient-row {
  width: 50%;
  margin-bottom: 20px;
  text-align: center;
  padding: 0px 20px;
  box-sizing: border-box;
}

.form-container {
  display: flex;
  align-items: center;
}

.img-container {
  margin-left: 6px;
  order: 2;
  align-self: stretch;
  display: flex;
  justify-content: flex-end;
}

.form-item-container {
  flex: 1;
  order: 1;
}

.img-container img {
  width: 80%;
  object-fit: contain;
}
</style>