<template>
  <el-dialog title="提示" :visible.sync="dialogVisible" width="90%" :before-close="handleClose">
    <el-form :model="shopFrom" label-width="100px" ref="shopFrom">
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="名称" width="120px" prop="name">
            <el-input width="20px" v-model="shopFrom.name" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="级别" width="120px" prop="grade">
            <el-select v-model="shopFrom.grade" filterable placeholder="请选择" style="width:90%">
              <el-option
                v-for="item in gradeList"
                :key="item.key"
                :label="item.value"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <!-- <el-col :span="8">
          <el-form-item label="分类" width="120px" prop="firstType">
            <el-input width="20px" v-model="shopFrom.firstType" disabled />
          </el-form-item>
        </el-col>-->
      </el-row>

      <!-- <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="图片" width="120px" prop="image">
            <el-input width="20px" v-model="shopFrom.image" />
          </el-form-item>
        </el-col>
      </el-row>-->

      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="数量" width="120px" prop="number">
            <el-input width="20px" v-model="shopFrom.number" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="预估价格" width="120px" prop="predictPrice">
            <el-input-number width="20px" v-model="shopFrom.predictPrice" />
          </el-form-item>
        </el-col>
        <el-col :span="8" v-if="this.types==5">
          <el-form-item label="付款价格" width="120px" prop="price">
            <el-input-number width="20px" v-model="shopFrom.price" />
          </el-form-item>
        </el-col>

        <!-- <el-col :span="8">
          <el-form-item label="来源" width="120px" prop="buySource">
            <el-input width="20px" v-model="shopFrom.buySource" />
          </el-form-item>
        </el-col>-->
      </el-row>

      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="标签" prop="labels">
            <el-tag
              :key="tag"
              v-for="tag in dynamicTags"
              closable
              :disable-transitions="false"
              @close="handleCloseTag(tag)"
            >{{tag}}</el-tag>
            <el-input
              class="input-new-tag"
              v-if="inputVisible"
              v-model="shopFrom.labels"
              ref="saveTagInput"
              size="small"
              @keyup.enter.native="handleInputConfirm"
              @blur="handleInputConfirm"
            ></el-input>
            <el-button v-else class="button-new-tag" size="small" @click="showInput">+ New Tag</el-button>
          </el-form-item>
        </el-col>
        <el-col :span="16">
          <el-form-item label="链接" width="120px" prop="buyUrl">
            <el-input width="20px" v-model="shopFrom.buyUrl" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="店铺" width="120px" prop="buyShop">
            <el-input width="20px" v-model="shopFrom.buyShop" />
          </el-form-item>
        </el-col>
        <el-col :span="16">
          <el-form-item label="地址" width="120px" prop="address">
            <el-input width="20px" v-model="shopFrom.address" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="加入计划" prop="isPlan">
            <el-switch
              v-model="shopFrom.isPlan"
              active-color="#ff4949"
              inactive-color="#13ce66"
              active-text="否"
              inactive-text="是"
              :active-value="2"
              :inactive-value="1"
            ></el-switch>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="加入收藏" prop="isCollect">
            <el-switch
              v-model="shopFrom.isCollect"
              active-color="#ff4949"
              inactive-color="#13ce66"
              active-text="否"
              inactive-text="是"
              :active-value="2"
              :inactive-value="1"
            ></el-switch>
          </el-form-item>
        </el-col>
        <el-col :span="8" v-if="this.shopFrom.isPlan==1">
          <el-form-item label="计划时间" width="120px" prop="planData">
            <el-date-picker
              v-model="shopFrom.planData"
              type="month"
              value-format="yyyy-MM"
              placeholder="选择月"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="24">
        <el-col :span="24">
          <el-form-item label="备注" width="120px" prop="remark">
            <el-input placeholder="请输入内容" v-model="shopFrom.remark" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="24">
        <el-col :span="24">
          <el-form-item label="商品信息" width="120px" prop="shopInfo">
            <el-input
              type="textarea"
              placeholder="请输入内容"
              v-model="shopFrom.shopInfo"
              show-word-limit
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="cancel()">取 消</el-button>
      <el-button type="primary" @click="addButton">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import {
  buyShopApi,
  addlifeshopApi,
  editlifeshopApi,
  selectlifeshopByIdApi
} from "@/api/life/shop/lifeshop";

export default {
  name: "AddLive",
  data() {
    return {
      dialogVisible: false,
      shopFrom: {
        isCollect: 2,
        isPlan: 2
      },
      dynamicTags: [],
      inputVisible: false,
      gradeList: [],
      ids: null,
      types: 1
    };
  },
  methods: {
    //打开弹窗
    showFund(id, type) {
      this.ids = id;
      this.dialogVisible = true;
      this.initEnumList();
      this.types = type;
      if (type == 2 || type == 5) {
        this.selectlifeshopButton();
      }
    },
    selectlifeshopButton() {
      selectlifeshopByIdApi(this.ids).then(res => {
        this.shopFrom = res.result;
        if (null != res.result.labels || "" != res.result.labels) {
          this.dynamicTags = res.result.labels.split(",");
        }
      });
    },

    cancel() {
      this.shopFrom = {};
      this.dynamicTags = [];
      this.shopFrom.isPlan = 1;
      this.shopFrom.isCollect = 2;
      this.$emit("shopFrom", false);
      this.dialogVisible = false;
      this.$parent.queryDataList();
      this.ids = null;
      this.$parent.id = null;
      this.$parent.questionnaireId = null;
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(_ => {
          this.cancel();
        })
        .catch(_ => {});
    },
    addButton() {
      this.shopFrom.labels = this.dynamicTags.toString();
      if (null == this.ids) {
        addlifeshopApi(this.shopFrom).then(res => {
          this.cancel();
        });
      } else {
        if (this.types == 5) {
          this.shopFrom.shopType = this.types;
          this.shopFrom.isBuy=1
          buyShopApi(this.shopFrom).then(res => {
            this.cancel();
          });
        } else {
          editlifeshopApi(this.shopFrom).then(res => {
            this.cancel();
          });
        }
      }
    },
    handleCloseTag(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm() {
      let inputValue = this.shopFrom.labels;
      if (inputValue) {
        this.dynamicTags.push(inputValue);
      }
      this.inputVisible = false;
      this.shopFrom.labels = "";
    },
    initEnumList() {
      this.gradeList = this.$parent.gradeList;
    }
  }
};
</script>
<style lang="less" scoped>
</style> 