<template>
    <div>
        <!-- 还需要一个系统配置的功能 配置全局的 logo 名称  图片-->
        <!--  系统集合配置： 默认首页 页面标题 标题点击跳转 页面logo 页面logo跳转 隐藏菜单 -->
        <el-dialog title="free" :visible.sync="drawer" :before-close="handleClose" :max-height="maxHeight" width="40%">
            <!-- <div class="form-container"> -->
            <!-- <div class="form-item-container"> -->
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="国家" prop="name">
                            <el-input v-model="ruleForm.name"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="省" prop="name">
                            <el-input v-model="ruleForm.name"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="市" prop="name">
                            <el-input v-model="ruleForm.name"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="标题" prop="names">
                            <el-input type="number" v-model="ruleForm.names" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="卡类型" prop="name">
                            <el-input v-model="ruleForm.name"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="优惠类型" prop="name">
                            <el-input v-model="ruleForm.name"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="卡等级" prop="name">
                            <el-input v-model="ruleForm.name" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="匹配规则" prop="name">
                            <el-input v-model="ruleForm.name" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="数据值" prop="name">
                            <el-input v-model="ruleForm.name"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="相关信息" prop="name">
                            <el-input v-model="ruleForm.name"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
                        <el-button>新增</el-button>
                        <el-tab-pane label="优惠明细" name="first">
                            <el-table :data="tableData" style="width: 100%">
                                <el-table-column prop="date" label="序号" />
                                <el-table-column prop="date" label="等级" />
                                <el-table-column prop="name" label="最低阈值" />
                                <el-table-column prop="name" label="最低运算符" />
                                <el-table-column prop="name" label="最高阈值" />
                                <el-table-column prop="name" label="最高运算符" />
                                <el-table-column prop="name" label="折扣率" />
                                <el-table-column prop="name" label="更新时间" />
                                <el-table-column prop="address" label="状态" />
                                <el-table-column prop="address" label="操作" />
                            </el-table>
                        </el-tab-pane>
                    </el-tabs>
                </el-row>
            </el-form>
        </el-dialog>
    </div>
</template>


<script>
  export default {
    data() {
      return {
        checked: true,
        previewShow: false,
        analyzeShow: false,
        fits: ["fill", "contain", "cover"],
        url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
        drawer: false,
        activeName: "first",
        ruleForm: {},
        rules: {},
        maxHeight: 400, // 设置max-height为窗口高度的一部分
        tableData: [],
        uploadUrl: "your-upload-url",
        fileList: [],
        listType: "picture-card", // 'text' or 'picture-card'
      };
    },
    mounted() {
      this.maxHeight = window.innerHeight * 0.8; // 设置max-height为窗口高度的80%
    },
    methods: {
      handleClick(tab, event) {
        if (this.previewShow) {
          this.previewShow = !this.previewShow;
        }
        if (this.analyzeShow) {
          this.analyzeShow = !this.analyzeShow;
        }
  
        console.log(tab, event);
      },
      show(row, type) {
        this.drawer = true;
      },
      handleClose(done) {
        this.$confirm("确认关闭？")
          .then((_) => {
            done();
          })
          .catch((_) => {});
      },
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            alert("submit!");
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
      handleSuccess(response, file, fileList) {
        console.log(response, file, fileList);
      },
  
      handleExceed(files, fileList) {
        this.$message.warning(
          `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
            files.length + fileList.length
          } 个文件`
        );
      },
      previewButton() {
        this.previewShow = !this.previewShow;
      },
    },
  };
  </script>
  <style  lang="less" scoped>
  .demo-ruleForm {
    overflow-y: auto;
    max-height: 90%; // 设置最大高度
  }
  
  .demo-drawer__footer {
    display: flex;
    justify-content: flex-end;
    padding: 0px 10px;
    border-top: 1px solid #ebeef5;
  }
  
  .ingredient-div {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 20px;
  }
  
  .ingredient-row {
    width: 50%;
    margin-bottom: 20px;
    text-align: center;
    padding: 0px 20px;
    box-sizing: border-box;
  }
  
  .form-container {
    display: flex;
    align-items: center;
  }
  
  .img-container {
    margin-left: 6px;
    order: 2;
    align-self: stretch;
    display: flex;
    justify-content: flex-end;
  }
  
  .form-item-container {
    flex: 1;
    order: 1;
  }
  
  .img-container img {
    width: 80%;
    object-fit: contain;
  }
  </style>